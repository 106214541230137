// Website skeleton - Layout

html {
  background-image: url($assets-path + 'access-background-3.png');
  background-size: 2000px;
  background-position: top left;
  background-repeat: repeat;
  background-attachment: fixed;
  position: relative;
  padding: ($spacer * 3) ($spacer * 3) 0;
  @include max-width(xl) {
    padding: ($spacer * 3) ($spacer * 2) 0;
  }
  @include max-width(lg) {
    padding: ($spacer * 3) ($spacer) 0;
  }
}

body {
  border-radius: $border-radius;
  box-shadow: rgba($black, 0.2) 0px 8px 24px;
  max-width: 1320px;
  margin: auto!important;
}

a.backToMainSite {
  color: white;
  padding: 20px 10px;
  display: block;
  font-family: $font-family-base;
  font-size: 12px;
  position: absolute;
  top: -5px;
  text-decoration: underline;
}

// Header - overwrite Fresco

.pageHeader .headerContent > *:not(.mainLogo) {
  margin: 0;
}

// Logo

.charityLogo {
  order: 2;
  max-height: 85px;
  img {
    max-height: 85px;
  }
  @media (max-width: $logo-breakpoint) {
    max-height: 64px;
    img {
      max-height: 64px;
    }
  }
}

// Hide nav on inner pages

body:not(.homepage) {
  .menuMain {
    display: none;
  }
  a.menuMainAlt {
      display: none;
  }
  @media (max-width: 1210px) {
    .pageHeader .headerContent {
        padding-bottom: 1rem;
    } 
  }
}

// Home intro

.homeIntroWrapper {
  font-size: 2em!important; // overwrite Fresco home intro weirdness
}

// Burger button - Mobile format

@media (max-width: $burger-btn-bar-breakpoint)  {
  .menuMainAlt {
    background-color: $brand-primary;
    color: text-contrast($brand-primary);
    &:hover, &.active {
      background-color: darken($brand-primary, 10%);
      color: text-contrast(darken($brand-primary, 10%));
    }
  }
}

// Carousel

.carouselSlideHeading a {
  @include highlighter (
    $background-colour: $_red
  );
  line-height: 1.7;
}
@media (min-width: $carousel-breakpoint) {
  .carouselSlideSummary {
    @include highlighter (
      $background-colour: white
    );
    line-height: 1.8;
  }
}
@media (max-width: $carousel-breakpoint) {
  .carouselSlideDetail {
    width: calc(100% - #{$card-gap-width});
    margin: -2rem auto 0;
    z-index: 2;
  }
}
.carousel span#totaliserActions {
  display: block;
  margin: 1em 0 0;
}

// Footer -  Layout

.pageFooter {
  max-width: $container-max-width;
  margin: 0 auto;
  display: flex;
  .footerBox {
    width: 100%;
    max-width: 831.22px;
    padding: 0 $site-bleed;
    margin: 0 auto;
    ul.footerLinks {
      margin-bottom: #{$spacer * 4};
    }
    img {
      max-height: 35px;
    }
  }

  @media (min-width: map-get($breakpoints, md) + 1px) {
    .footerBox {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      > * {
        flex: 1;
        margin: 0;
      }
      p {
        text-align: left;
      }
      img {
        object-fit: contain;
        object-position: right bottom;
      }
    }
    ul.footerLinks {
      flex: unset;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      li  {
        margin: 0 5px;
        &:not(:last-of-type):after {
          content: '|';
          display: inline-flex;
          padding-left: 5px;
        }
      }
    }
  }

}

// Main site - Home feature logo overlays

.homeFeature {
  &:before {
    content: '';
    background-repeat: no-repeat;
    background-size: auto 50px;
    background-color: white;
    background-position: 10px 10px;
    border-radius: 5px;
    height: 70px;
    z-index: 20;
    position: absolute;
    margin: 0;
    right: 30px;
    top: 30px;
    @media (max-width: 350px) {
      right: 10px;
      top: 10px;
    }
  }
  &.postc532d99c6b65439a8dc82ef252121d2c:before {
    background-image: url($assets-path + 'logo-australia.png');
    width:135px;
  }
  &.postb06d34dd4cf84b9bb786e83d1eec069f:before {
    background-image: url($assets-path + 'logo-ireland.jpg');
    width: 155px;
  }
  &.postc166efd01dd645b6b41a5e97f3b93824:before {
    background-image: url($assets-path + 'logo-malaysia.png');
    width: 134px;
  }
  &.post5be557d4baf649da834f715c374a3280:before {
    background-image: url($assets-path + 'logo-new-zealand.png');
    width:104px;
  }
  &.post7891655c50fc43b7826b26f071bf4044:before {
    background-image: url($assets-path + 'logo-romania.png');
    width:80px;
  }
  &.post71e8d9f6cc7d4172a1d0b57d67ca1272:before {
    background-image: url($assets-path + 'logo-singapore.jpg');
    width: 85px;
    background-size: auto 80px;
    height: 100px;
  }
  &.post10b40ff03d3e4c9794f21821a7b0fd4b:before {
    background-image: url($assets-path + 'logo-sri-lanka.jpg');
    width:80px;  
  }
  &.post611115850ece491d9593c54422b2cfa5:before {
    background-image: url($assets-path + 'logo-uk.png');
    width:115px;     
  }
  &.post7e5753e1ee964868aa1576f457b83849:before {
    background-image: url($assets-path + 'logo-usa.png');
    width:180px;    
  }
  &.post866c47589f554a49bc841eee457d0535:before {
    background-image: url($assets-path + 'logo-vietnam.png');
    width:195px;    
  }
}

// Appeals 

.appealPostPage, .fundraiserPostPage {
  .appealActionsWrapper {
    display: block;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 15px 20px
  }
}
.content.appealActions {
  display: block;
  margin: auto;
  width: 100%;
  max-width: calc(1024px - 40px);
}

.post .appealActionsWrapper .appealActions .button {
  margin: 0 8px 0 0; /* overwrite base Fesco button styling as cta buttons on inner pages don't have this */
}

.postAside {
  .totaliserBG {
    @media (max-width: 768px) {
      height: calc(100vw - (#{$gap-width * 2}) - #{$site-bleed * 2} - #{$spacer * 2});
    }
  }
}

// Donate form - hide carousel

.formDonatePage section.carousel.carouselImageSingle {
  display: none;
}

// Individual appeal posts - change currency for totalisers etc

body[class*="malaysia"],
body[class*="romania"],
body[class*="singapore"],
body[class*="lanka"],
body[class*="usa"],
body[class*="vietnam"] {
  .raisedAmount, 
  .targetAmount {
    color: white;
    &:before {
      content: '$';
      color: $_red;
    }
    span {
      color: $_red;
    }
  }
  .raisedAmount span {
    margin-left: -11px;
  }
  .targetAmount span {
    margin-left: -7px;
  }
}

body[class*="malaysia"] {
  .raisedAmount:before, 
  .targetAmount:before {
    content: 'RM';
  }
}

body[class*="romania"] {
  .raisedAmount:before, 
  .targetAmount:before {
    content: 'lei';
  }
  .contentBlockWrapper.appealActionsWrapper {
    display: none; // Hide donate button as iframe being used so no external donation url to link to
  }
}

body[class*="lanka"] {
  .raisedAmount:before, 
  .targetAmount:before {
    content: 'Rs';
  }
}

// Fundraisers listing page

.findAFundraiserForm .formQuestion.findAFundraiserName {
  margin-bottom: 0;
  padding-bottom: 0;
}

.listedPost:not(.listedSearchResult):not(.listedMapItem) {
  .listed-post-image-link, .banner {
    border-radius: 10px;
  }
  h2 a, 
  &:hover h2 a,
  h3 {
    color: $_red;
  }
  h3 {
    font-size: 1em!important;
    margin: 1em 0;
    font-weight: normal;
  }
}