@mixin highlighter(
  $background-colour: $brand-primary,
  $colour: text-contrast($background-colour),
  $padding-x: .5rem,
  $padding-y: .75rem,
  $line-height: 1.6,
  $border-radius: 0
) {
  display: inline;
  margin: 0;
  padding: $padding-x $padding-y;
  background: $background-colour;
  color: $colour;
  line-height: $line-height;
  box-decoration-break: clone;

  @if $border-radius != 0 {
    border-radius: $border-radius;
  }
}