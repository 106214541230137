// Home features - Card layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 6,
    $prefab: 4
  );

  // Home features - Overall card style
  .homeFeature:not(.homeFeatureSnippets) {
    h2 {
      @include highlighter (
        $background-colour: $_red
      );
      line-height: 1.8;
    }
    @include card(
      $card-border-radius: 10px
    );
    @include card-text-overlay
  }
}

// Home feeds - Overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Home feeds - Card layout
.homeFeed .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 3
  );
  // Home feeds - Overall card style
  .feedItem {
    @include card;
    @include card-basic;
    h3 {
      background-color: $brand-primary;
      border-radius: $border-radius;
    }
  }
}

// Listed posts - Overall card style
.listedPost:not(.listedSearchResult):not(.listedMapItem) {
  @include card(
    $card-border-radius: 10px
  );
  @include card-basic;
}